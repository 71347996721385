<template>
	<div class="wrap_row">
		<div class="row">
			<label class="form_tit">
				<span class="txt_large">서비스 약관 동의</span>
			</label>
			<div class="form_data">
				<div class="wrap_terms">
					<div class="tit">
						<div class="com_ck bk">
							<label
								>서비스 약관 동의 (전체)<input type="checkbox" v-model="agreeAll" @click="reverseAgreeAll" /><span
									class="checkmark"
								></span
							></label>
						</div>
					</div>
					<ul class="list_terms">
						<li v-for="(item, index) in termsModels" :key="index">
							<div class="com_ck bk">
								<label
									>{{ item.termsName }}
									<template>{{ item.required ? '(필수)' : '(선택)' }}</template>
									<input
										type="checkbox"
										:checked="item.approved"
										@click="
											item.approved = !item.approved;
											checkAllApproved();
										"
									/>
									<span class="checkmark"></span>
								</label>
								<span class="ic_set ic_arrow_r" v-on:click="openTermsLayer(item)"></span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import termsTemplate from '@/components/apply/termsDetail.vue';
import { estimateTermsData } from '@/utils/constants';
export default {
	props: {
		type: {
			type: String,
		},
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			termsModels: [],
			agreeAll: false,
			openLayer: false,
			openTerms: {},
		};
	},
	created() {
		this.setTerms();
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$emit('setTermsAgreement', this.termsModels);
			}
		},
		agreeAll() {
			if (this.agreeAll) {
				this.termsModels.forEach((obj) => {
					obj.approved = this.agreeAll;
				});
			}
		},
		termsModels: {
			deep: true,
			handler() {
				this.checkAllApproved();
			},
		},
	},
	methods: {
		reverseAgreeAll() {
			this.agreeAll = !this.agreeAll;
			if (this.termsModels.length == 0) {
				return;
			}
			this.termsModels.forEach((obj) => {
				obj.approved = this.agreeAll;
			});
		},
		checkAllApproved() {
			const filter = this.termsModels.filter((obj) => !obj.approved);
			this.agreeAll = filter.length == 0 ? true : false;
		},
		async setTerms() {
			if (this.type == 'ESTIMATE') {
				estimateTermsData.forEach((item) => this.termsModels.push(item));
			} else {
				const baseItem = this.$store.getters.getBasicItemSelected[0];
				if (baseItem.termsSectionModel.useTerms) {
					if (baseItem.termsSectionModel.termsModels.length > 0) {
						await baseItem.termsSectionModel.termsModels.forEach((obj) => {
							if (!obj.useForm) {
								return;
							}
							obj['approved'] = false;
							this.termsModels.push(obj);
						});
					}
				}
				this.termsModels = await this.cloneObj(this.termsModels);
			}
		},
		openTermsLayer(item) {
			this.openTerms = item;
			this.$store.commit('initAgreeTerm');
			this.$modal.show(
				termsTemplate,
				{
					info: item,
				},
				{
					width: '800px',
					height: '715px',
					draggable: true,
				},
				{
					closed: this.closeTermsLayer,
				},
			);
		},
		async closeTermsLayer() {
			const agreeTerm = this.$store.getters.getAgreeTerm;
			const temp = await this.cloneObj(this.termsModels);
			if (agreeTerm) {
				const idx = temp.findIndex((term) => term.id == this.openTerms.id);
				temp[idx].approved = true;
			}
			this.termsModels = temp;
			this.checkAllApproved();
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
	},
};
</script>
