<template>
	<div>
		<div class="section_body section_join2">
			<div class="section_content">
				<div class="com_stepper">
					<ul>
						<li class="complete"><i>step1</i></li>
						<li class="current"><i>step2</i></li>
						<li><i>step3</i></li>
					</ul>
				</div>

				<div class="com_form_large form_join2">
					<!--@--주문정보-->
					<order-info></order-info>
					<!--@--결제방법-->
					<div class="wrap_row" v-show="!hidePayMethodInfo()">
						<div class="row">
							<label class="form_tit">
								<span class="txt_large">결제 방법</span>
							</label>
							<pay-method :saveFlag="save" :orderType="'NEW'" v-on:setPayMethodInfo="setPayMethodInfo"></pay-method>
						</div>
					</div>
					<!--@약관동의-->
					<terms-agreement-info :saveFlag="save" v-on:setTermsAgreement="setTermsAgreement"></terms-agreement-info>
				</div>
				<step-footer :disable="disable" :stepBtnInfo="stepBtnInfo" :actionBeforeMove="saveInput"></step-footer>
			</div>
		</div>
		<div v-if="toastOpen" class="com_layer_toast" style="left: 25%; top: 80%; z-index: 9998">
			<div class="lay_header">
				<h1>
					주문 생성중입니다. 잠시만 기다려주세요.
					<span class="ic_set ic_sync"></span>
				</h1>
				<h1>( 완료 후, 자동으로 결제창 실행 또는 페이지 이동 됩니다. )</h1>
			</div>
		</div>
	</div>
</template>
<script>
import OrderInfo from '@/components/apply/resultInfo/OrderInfo.vue';
import PayMethod from '@/components/payment/method/PayMethod.vue';
import StepFooter from '@/components/common/StepFooter.vue';
import TermsAgreementInfo from '@/components/apply/commonInfo/termsAgreementInfo.vue';
import ToastComponent from '@/components/code/modal/ToastComponent.vue';
import { payment } from '@/components/apply/mixinsPayment.js';
export default {
	mixins: [payment],
	data() {
		return {
			disable: false,
			toastOpen: false,
			validPayMethod: false, // 결제 방법 관련 valid
			validTerms: false, // 결제 방법 관련 valid
			stepBtnInfo: {
				next: this.isFree() ? ' 신청하기' : '결제하기',
				prevLink: '/apply/service/step1',
			},
			paymentUrl: '/kiwoom/popup',
			enablePopup: false,
			saveFlag: false,
			payMethodInfo: {},
			input: {
				payMethodInfo: null,
				termsAgreementInfo: null,
			},
			save: false,
			newOrder: null, // 신규 주문
		};
	},
	watch: {
		async 'input.payMethodInfo'() {
			if (this.input.payMethodInfo && this.input.termsAgreementInfo) {
				await this.dataValidation();
				this.input.payMethodInfo = null;
				this.input.termsAgreementInfo = null;
				this.save = !this.save;
			}
		},
		async 'input.termsAgreementInfo'() {
			if (this.input.payMethodInfo && this.input.termsAgreementInfo) {
				await this.dataValidation();
				this.input.payMethodInfo = null;
				this.input.termsAgreementInfo = null;
				this.save = !this.save;
			}
		},
	},
	methods: {
		hidePayMethodInfo() {
			return this.isFree();
		},
		isFree() {
			const totalPrice = this.$store.getters.getSummuryPrices.totPayPrice;
			//const chargeType = this.$store.getters.getBasicItemSelected[0].chargeType;
			return parseInt(totalPrice) == 0;
		},
		async dataValidation() {
			const payMethodInfo = this.input.payMethodInfo;
			const termsAgreementInfo = this.input.termsAgreementInfo;
			if (payMethodInfo.payType == 'AUTOEXTENSION') {
				if (!this.checkAutoExtension(payMethodInfo)) {
					return false;
				}
			}

			if (
				(payMethodInfo.payType == 'DEPOSIT' || payMethodInfo.payType == 'REALTIMETRANSPORT') &&
				payMethodInfo.issuedBill &&
				!this.isFree()
			) {
				const businessRegistration = this.$store.getters.getBusinessRegistration;
				if (businessRegistration.length == 0) {
					this.openToast('세금계산서 신청시 사업자등록증은 필수입니다.');
					return false;
				}
			}

			if (!payMethodInfo.infoConsent && !this.isFree()) {
				this.openToast('결제 방법 안내 동의가 필요합니다.');
				return false;
			}
			// 약관 동의 체크
			for (let idx in termsAgreementInfo) {
				const terms = termsAgreementInfo[idx];
				if (terms.required && !terms.approved) {
					this.openToast('[' + terms.termsName + '] 약관은 필수 동의 항목입니다.');
					return false;
				}
			}

			await this.$store.commit('setPayMethodInfo', payMethodInfo);
			this.payMethodInfo = payMethodInfo;

			await this.$store.commit('setTermsAgreementInfo', termsAgreementInfo);
			await this.apply();

			return true;
		},
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
		saveInput() {
			this.save = !this.save;
		},
		closePopup() {
			this.enablePopup = false;
			this.saveFlag = false;
		},
		async apply() {
			//this.$ms.modal.show(Kiwoompay);
			this.toastOpen = true;
			this.disable = true;
			this.data = await this.createNewOrder();

			if (!this.data) {
				this.toastOpen = false;
				this.$ms.dialog.alert(
					'시스템 에러 발생',
					'주문 생성 중, 시스템 에러가 발생하였습니다. 시스템 담당자에게 문의해주세요.',
				);
				return;
			}
			this.disable = false;
			this.payMethodInfo['amount'] = this.$store.getters.getSummuryPrices.finalPayPrice;
			this.payMethodInfo['orderId'] = this.data.id;
			this.payMethodInfo['orderSummary'] = this.data.orderSummary ? this.data.orderSummary : ' ';
			this.payMethodInfo['corporateName'] = this.data.customer.companyInfo.corporateName;

			await localStorage.setItem('payMethodInfo', JSON.stringify(this.payMethodInfo));
			if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
				// 자동결제 정보 저장
				await this.saveAutoExtensionInfo(this.data);
			} else {
				await this.goNext();
			}
			this.toastOpen = false;
		},
		async goNext() {
			if (this.payMethodInfo.payType == 'DEPOSIT' || this.payMethodInfo.payType == 'AUTOEXTENSION') {
				await this.goNextPage('/apply/service/step3');
			} else {
				await this.goPayment(this.payMethodInfo, '/apply/service/step3');
			}
		},
		async createNewOrder() {
			// 주문생성 API

			var param = await this.makeNewOrderData();
			let payType = '';
			if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
				payType = 'AUTO_CREDITCARD';
			} else if (this.payMethodInfo.payType == 'CREDITCARD') {
				payType = 'CREDITCARD';
			} else if (this.payMethodInfo.payType == 'DEPOSIT') {
				payType = 'TRANSPORT';
			} else if (this.payMethodInfo.payType == 'REALTIMETRANSPORT') {
				payType = 'REALTIME_TRANSPORT';
			}
			param.payType = payType;
			let res;
			let cnt = 0;
			let errorFlag = false;
			while (!res && !errorFlag && cnt <= 30) {
				cnt++;
				try {
					res = await this.$store.dispatch('CREATENEWORDERINFO', param);
					if (res.error) {
						errorFlag = true;
					}
				} catch (error) {
					if (error) {
						errorFlag = true;
					}
				}
			}
			if (errorFlag || cnt > 30) {
				this.$ms.dialog.alert(
					'시스템 에러 발생',
					'주문 생성 중, 시스템 에러가 발생하였습니다. 시스템 담당자에게 문의해주세요.',
				);
				return false;
			} else {
				return res.data;
			}
		},
		async saveAutoExtensionInfo(data) {
			const result = await this.setAutoExtension(this.payMethodInfo, data.id, data.customer.id); // 자동결제 등록

			if (result) {
				await this.goNextPage('/apply/service/step3');
			}
		},
		async applyOrder(data) {
			await this.$store.dispatch('APPLYNEWORDERINFO', data); // 입금확인
		},
		getCustomerTermsAgreementInfo() {
			const current = this.$store.getters.getStartDate;
			const agreeInfo = [];

			const termsOfUse = this.getTermsInfo('TERMS_OF_USE'); // 서비스이용약관
			const termsOfPrivacy = this.getTermsInfo('TERMS_OF_PRIVACY'); //개인정보 수집 약관
			const termsOfAge = this.getTermsInfo('TREMS_OF_AGE'); //만14세 이상
			const termsOfLinkPlus = this.getTermsInfo('TERMS_OF_LINKPLUS'); //링크플러스
			const termsOfMarketing = this.getTermsInfo('TERMS_OF_MARKETING'); //마케팅 동의
			const termsOfLocation = this.getTermsInfo('TERMS_OF_LOCATION'); //위치기반서비스 활용동의
			const termsArray = [termsOfUse, termsOfPrivacy, termsOfAge, termsOfLinkPlus, termsOfMarketing, termsOfLocation];
			termsArray.forEach((term) => {
				if (!term) {
					return;
				}
				const info = {
					type: term.termsType,
					agreeCheck: term.approved,
					agreeDate: current,
				};
				agreeInfo.push(info);
			});
			return agreeInfo;
		},
		getTermsInfo(termsType) {
			const termsAgreementInfo = this.input.termsAgreementInfo;
			return termsAgreementInfo.find((o) => o.termsType == termsType);
		},
		getManagerInfo() {
			const allCompanyInfo = this.$store.getters.getAllCompanyInfo.companyManagerInfo;
			const current = this.$store.getters.getStartDate;
			const mangerInfo = [
				{
					type: 'PAYMENT',
					name: allCompanyInfo.name,
					email: allCompanyInfo.email,
					phone: allCompanyInfo.phone,
					grade: allCompanyInfo.grade,
					contact: allCompanyInfo.tel,
					agreePrivacyInfoTermDate: current,
					agreePrivacyInfoTerm: this.getTermsInfo('TERMS_OF_PRIVACY').approved, //개인정보수집
					agreeMarketingTermDate: current,
					agreeMarketingTerm: this.getTermsInfo('TERMS_OF_MARKETING').approved, //마케팅 수집
				},
			];
			return mangerInfo;
		},
		async getCompanyInfo() {
			// 1. customer(고객사정보)
			const allCompanyInfo = this.$store.getters.getAllCompanyInfo;
			const companyInfo = {
				corporateName: allCompanyInfo.companyInfo.corporateName,
				businessLicenseNum: allCompanyInfo.companyInfo.businessLicenseNum,
				ceoName: allCompanyInfo.companyInfo.ceoName,
				companyAddress: allCompanyInfo.companyInfo.companyAddress,
				zipCode: allCompanyInfo.companyInfo.companyZipCode,
				repNumber: allCompanyInfo.companyInfo.directPhone,
				industryCategory: allCompanyInfo.companyInfo.industrySector ? allCompanyInfo.companyInfo.industrySector : '',
				businessCondition: allCompanyInfo.companyInfo.businessCondition
					? allCompanyInfo.companyInfo.businessCondition
					: '',
				businessType: allCompanyInfo.companyInfo.businessType ? allCompanyInfo.companyInfo.businessType : '',
				taxInvoiceManager: {
					taxInvoiceManagerName: allCompanyInfo.companyManagerInfo.issuedBillName,
					taxInvoiceEmail: allCompanyInfo.companyManagerInfo.issuedBillEmail,
				},
				businessLicenses: await this.$store.getters.getBusinessRegistration,
				employeeCount: parseInt(allCompanyInfo.companyInfo.employeeCount),
			}; // 회사정보
			return companyInfo;
		},
		isProductChargeTypeFree() {
			return this.$store.getters.getBasicItemSelected[0].chargeType === 'FREE';
		},
		isMembershipChargeTypeFree() {
			const baseItemSelected = this.$store.getters.getBasicItemSelected[0];
			const addonItemSelected = this.$store.getters.getAddonItemSelected;
			const hasBaseItemMonthly = this.existMonthlyFeeItem(baseItemSelected.optionItemSelected);
			const hasAddonItemMonthly = this.existMonthlyFeeAddonItem(addonItemSelected);

			//기본서비스가 무료 이고 기본서비스,옵션서비스 에서 선택한 아이템 중에 월과금이 하나도 없어야 무료 일반이다.
			return baseItemSelected.chargeType == 'FREE' && !hasBaseItemMonthly && !hasAddonItemMonthly;
		},
		existMonthlyFeeItem(optionItemSelected) {
			const monthlyItems = optionItemSelected.filter(
				(o) => o.salesRecognitionType == 'MONTHLY' && parseInt(o.finalPrice) > 0,
			);
			return monthlyItems.length > 0;
		},
		existMonthlyFeeAddonItem(addonItemSelected) {
			const hasMonthlyItems = addonItemSelected.filter((o) => this.existMonthlyFeeItem(o.optionItemSelected));
			return hasMonthlyItems.length > 0;
		},
		async makeNewOrderData() {
			const current = this.$store.getters.getStartDate;
			const baseItemSelected = this.$store.getters.getBasicItemSelected[0];
			const endDate = this.$store.getters.getEndDate;
			const allCompanyInfo = this.$store.getters.getAllCompanyInfo;

			//1.고객사 정보
			const customer = {
				companyInfo: await this.getCompanyInfo(),
				managerList: this.getManagerInfo(),
				erpTransferTarget: 'ERP',
				// termsPolicyList: this.getCustomerTermsAgreementInfo(),
			};
			const representativeCustomer = {
				customerModel: customer,
				termsPolicyList: this.getCustomerTermsAgreementInfo(),
			};

			// 2. baseInfoModel
			const baseInfoModel = {
				orderType: 'FRESH',
				orderMonthCount: baseItemSelected.usePeriod,
				orderDate: this.$utils.currentLocalDateTime(), //오늘
				applyDate: current, //오늘
				orderEndDate: endDate, //오늘
				salesRepresentative: null,
				hasEndDate: !this.isMembershipChargeTypeFree(), //무료일반일 경우 false
			};
			// 3. orderSummary
			const orderSummary =
				baseItemSelected.name + ' ' + baseItemSelected.usePeriod + '개월 ' + baseItemSelected.userCnt + '명';
			let partnerSimpleModel = null;
			const partnerInfo = this.$store.getters.getPartnerInfo;

			if (partnerInfo) {
				partnerSimpleModel = partnerInfo;
			} else {
				// 4. partnerInfo(다우기술 fix)
				partnerSimpleModel = {
					partnerId: this.isProductChargeTypeFree()
						? window.__env.code.FREE_PARTNER_ID
						: window.__env.code.PAY_PARTNER_ID,
					partnerName: 'test partner',
					useSales: false,
					useAlliance: false,
					salesType: null,
					allianceType: null,
					discountCollection: null,
				};
			}
			// 5. serviceType
			const serviceType = baseItemSelected.serviceCategoryModel.serviceType;
			// 6. payType
			let payType = '';

			if (this.payMethodInfo.payType == 'AUTOEXTENSION') {
				payType = 'AUTO_CREDITCARD';
			} else if (this.payMethodInfo.payType == 'CREDITCARD') {
				payType = 'CREDITCARD';
			} else if (this.payMethodInfo.payType == 'DEPOSIT') {
				payType = 'TRANSPORT';
			} else if (this.payMethodInfo.payType == 'REALTIMETRANSPORT') {
				payType = 'REALTIME_TRANSPORT';
			}

			// 기본서비스 정보
			// 7. baseChannelOrderInfoModel(BaseChannelOrderInfoModel)
			// baseChannelModel
			const baseChannelModel = baseItemSelected;
			// baseChannelBaseInfoModel (개설정보)
			let baseChannelBaseInfoModel = {
				siteName: allCompanyInfo.companySiteInfo.basicItem.siteName,
				url: allCompanyInfo.companySiteInfo.basicItem.url + '.daouoffice.com',
				domain: allCompanyInfo.companySiteInfo.basicItem.domain,
				adminName: allCompanyInfo.companyManagerInfo.name,
				adminLoginId: allCompanyInfo.companySiteInfo.basicItem.masterId,
				adminPhone: allCompanyInfo.companyManagerInfo.phone,
				virtualDomain: [],
			}; // to-do : virtualDomain 추가. 옵션 선택시

			//멀티사이트를 주문한 경우
			if (allCompanyInfo.companySiteInfo.basicItem.doClusterId) {
				baseChannelBaseInfoModel.doCluster = {
					id: allCompanyInfo.companySiteInfo.basicItem.doClusterId,
				};
				baseChannelBaseInfoModel.multiSiteName = allCompanyInfo.companySiteInfo.basicItem.multiSiteName;
			}

			const emailRecipientModel = {
				name: allCompanyInfo.companyManagerInfo.name,
				email: allCompanyInfo.companyManagerInfo.email,
				phone: allCompanyInfo.companyManagerInfo.phone,
			};
			// applyDate, endDate
			// membershipModel (신규는 null)
			const membershipModel = null;
			const periodPolicyModel = baseItemSelected.periodPolicyModel;
			let periodDiscountInfoModel = null;
			const periodPolicyIdx = periodPolicyModel.discountRate.findIndex(
				(obj) => obj.month == baseItemSelected.usePeriod,
			);
			if (periodPolicyModel.useLongTermPrepaymentDiscount && periodPolicyIdx > -1) {
				periodDiscountInfoModel = {
					discountName: baseItemSelected.usePeriod + '개월 장기선납',
					discountType: 'PERIOD',
					discountRate: parseInt(periodPolicyModel.discountRate[periodPolicyIdx].rate),
				};
			}
			// 각 금액(기본료,설치비,사용자추가) detail
			// defaultFeeOrderDetail(기본료 상세)
			const defaultFeeDiscountIdx = baseItemSelected.discountPolicyModels.findIndex(
				(obj) => obj.itemType == 'DEFAULT_FEE' && obj.useNew,
			);
			let defaultFeeDiscountInfoModels = [];
			if (defaultFeeDiscountIdx > -1) {
				const discountPolicyModel = baseItemSelected.discountPolicyModels[defaultFeeDiscountIdx];

				const discountInfoModel = {
					discountName: discountPolicyModel.name,
					discountType: 'PROMOTION',
				};
				if (discountPolicyModel.benefitType == 'RATE') {
					// 율 할인
					discountInfoModel['discountRate'] = discountPolicyModel.num;
				} else if (discountPolicyModel.benefitType == 'AMOUNT') {
					// 금액할인
					discountInfoModel['discountPrice'] = discountPolicyModel.num;
				}

				defaultFeeDiscountInfoModels.push(discountInfoModel);
			}

			if (periodDiscountInfoModel) {
				defaultFeeDiscountInfoModels.push(periodDiscountInfoModel);
			}
			const defaultFeeOrderDetail = {
				channelItemSimpleModel: null,
				channelItemType: 'DEFAULT_FEE',
				itemCode: baseItemSelected.defaultFeePolicyModel.code,
				totalOrderCount: 1,
				existingCount: 0,
				addCount: 1,
				price: baseItemSelected.defaultFeePolicyModel.price, // 단가
				finalPrice: baseItemSelected.defaultFeeDiscountPrice,
				note: '기본료',
				useFulfillment: false,
				discountInfoModels: defaultFeeDiscountInfoModels,
				status: 'USING',
				itemCancelInfoModel: null,
			};

			// membershipFeeOrderDetail(설치비 상세)
			const membershipFeeDiscountIdx = baseItemSelected.discountPolicyModels.findIndex(
				(obj) => obj.itemType == 'MEMBERSHIP_FEE' && obj.useNew,
			);
			let membershipFeeDiscountInfoModels = [];
			if (membershipFeeDiscountIdx > -1) {
				const discountPolicyModel = baseItemSelected.discountPolicyModels[membershipFeeDiscountIdx];

				const discountInfoModel = {
					discountName: discountPolicyModel.name,
					discountType: 'PROMOTION',
				};
				if (discountPolicyModel.benefitType == 'RATE') {
					// 율 할인
					discountInfoModel['discountRate'] = discountPolicyModel.num;
				} else if (discountPolicyModel.benefitType == 'AMOUNT') {
					// 금액할인
					discountInfoModel['discountPrice'] = discountPolicyModel.num;
				}

				membershipFeeDiscountInfoModels.push(discountInfoModel);
			}
			const membershipFeeOrderDetail = {
				channelItemSimpleModel: null,
				channelItemType: 'MEMBERSHIP_FEE',
				itemCode: baseItemSelected.membershipFeePolicyModel.code,
				totalOrderCount: 1,
				existingCount: 0,
				addCount: 1,
				price: baseItemSelected.membershipFeePolicyModel.price,
				finalPrice: baseItemSelected.membershipFeeDiscountPrice,
				note: null,
				discountInfoModels: membershipFeeDiscountInfoModels,
				status: 'USING',
				itemCancelInfoModel: null,
			};
			// to-be : addUserFeeOrderDetails
			let addUserFeeOrderDetails = [];
			const addUserFeeDiscountIdx = baseItemSelected.discountPolicyModels.findIndex(
				(obj) => obj.itemType == 'ADD_USER' && obj.useNew,
			);
			let addUserFeeDiscountInfoModels = [];
			if (addUserFeeDiscountIdx > -1) {
				const discountPolicyModel = baseItemSelected.discountPolicyModels[addUserFeeDiscountIdx];

				const discountInfoModel = {
					discountName: discountPolicyModel.name,
					discountType: 'PROMOTION',
				};
				if (discountPolicyModel.benefitType == 'RATE') {
					// 율 할인
					discountInfoModel['discountRate'] = discountPolicyModel.num;
				} else if (discountPolicyModel.benefitType == 'AMOUNT') {
					// 금액할인
					discountInfoModel['discountPrice'] = discountPolicyModel.num;
				}

				addUserFeeDiscountInfoModels.push(discountInfoModel);
			}

			if (periodDiscountInfoModel) {
				addUserFeeDiscountInfoModels.push(periodDiscountInfoModel);
			}
			baseItemSelected.addUserDiscountMatrix.forEach((data) => {
				addUserFeeOrderDetails.push({
					channelItemSimpleModel: null,
					channelItemType: data.addUserIntervals.channelItemType, // itemType으로 변경
					itemCode: data.code,
					itemName: '사용자추가(' + data.addUserIntervals.min + '~' + data.addUserIntervals.max + ')',
					totalOrderCount: data.discountCnt,
					existingCount: 0,
					addCount: data.discountCnt,
					price: data.price,
					finalPrice: data.totPrice,
					note: null,
					discountInfoModels: addUserFeeDiscountInfoModels,
					status: 'USING',
					itemCancelInfoModel: null,
				});
			});

			//itemOrderDetails(옵션상품리스트)
			let itemOrderDetails = [];
			baseItemSelected.optionItemSelected.forEach((data) => {
				var text = '';
				// 가상도메인 추가 시
				if (data.itemType == 'VIRTUAL_DOMAIN') {
					data.domainText.forEach((domainText) => {
						if (domainText) {
							baseChannelBaseInfoModel.virtualDomain.push(domainText);
							text += domainText + ',';
						}
					});
				}

				if (text.length > 0) {
					text = text.substr(0, text.length - 1);
				}

				data['billingType'] = data.channelItemSalesSectionModel.billingType;
				data['noCharge'] = data.channelItemSalesSectionModel.noCharge;
				itemOrderDetails.push({
					channelItemSimpleModel: data,
					channelItemType: data.itemType,
					itemCode: data.code,
					itemName: data.name,
					totalOrderCount: data.orderCount,
					existingCount: 0,
					addCount: data.orderCount,
					price: data.channelItemSalesSectionModel.price,
					finalPrice: data.finalPrice,
					note: text.length > 0 ? text : data.name,
					discountInfoModels: [],
					status: 'USING',
					itemCancelInfoModel: null,
				});
			});

			const baseChannelOrderInfoModel = {
				baseChannelModel: baseChannelModel,
				baseChannelBaseInfoModel: baseChannelBaseInfoModel,
				applyDate: current,
				endDate: endDate,
				itemOrderDetails: itemOrderDetails,
			};

			if (baseItemSelected.membershipFeePolicyModel.usePolicy) {
				baseChannelOrderInfoModel['membershipFeeOrderDetail'] = membershipFeeOrderDetail;
			}

			if (baseItemSelected.defaultFeePolicyModel.usePolicy) {
				baseChannelOrderInfoModel['defaultFeeOrderDetail'] = defaultFeeOrderDetail;
			}

			if (baseItemSelected.addUserIntervalPolicyModel.usePolicy) {
				baseChannelOrderInfoModel['addUserFeeOrderDetails'] = addUserFeeOrderDetails;
			}

			// 부가서비스 정보
			// 8. addonChannelOrderInfoModels(AddonChannelOrderInfoModel list)
			const addonItemSelectedList = this.$store.getters.getAddonItemSelected;
			let addonChannelOrderInfoModels = [];
			addonItemSelectedList.forEach((addonItem) => {
				const addonChannelModel = addonItem;
				// 신규 개설 시 필요 정보
				let addonChannelBaseInfoModel = {};
				const companySiteInfoIdx = allCompanyInfo.companySiteInfo.addonItem.findIndex(
					(obj) => obj.name == addonItem.name,
				);
				if (companySiteInfoIdx > -1) {
					addonChannelBaseInfoModel = {
						url: allCompanyInfo.companySiteInfo.addonItem[companySiteInfoIdx].url,
						adminLoginId: allCompanyInfo.companySiteInfo.addonItem[companySiteInfoIdx].masterId,
					};
				} // to-do : virtualDomain 추가. 옵션 선택시

				// 장기선납
				const periodPolicyModel = addonItem.periodPolicyModel;
				let periodDiscountInfoModel = null;
				const periodPolicyIdx = periodPolicyModel.discountRate.findIndex((obj) => obj.month == addonItem.usePeriod);
				if (periodPolicyModel.useLongTermPrepaymentDiscount && periodPolicyIdx > -1) {
					periodDiscountInfoModel = {
						discountName: addonItem.usePeriod + '개월 장기선납',
						discountType: 'PERIOD',
						discountRate: parseInt(periodPolicyModel.discountRate[periodPolicyIdx].rate),
					};
				}

				// 각 금액(기본료,설치비,사용자추가) detail
				// defaultFeeOrderDetail(기본료 상세)
				const addonDefaultFeeDiscountIdx = addonItem.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'DEFAULT_FEE' && obj.useNew,
				);
				let addonDefaultFeeDiscountInfoModels = [];
				if (addonDefaultFeeDiscountIdx > -1) {
					const discountPolicyModel = addonItem.discountPolicyModels[addonDefaultFeeDiscountIdx];

					const discountInfoModel = {
						discountName: discountPolicyModel.name,
						discountType: 'PROMOTION',
					};
					if (discountPolicyModel.benefitType == 'RATE') {
						// 율 할인
						discountInfoModel['discountRate'] = discountPolicyModel.num;
					} else if (discountPolicyModel.benefitType == 'AMOUNT') {
						// 금액할인
						discountInfoModel['discountPrice'] = discountPolicyModel.num;
					}

					addonDefaultFeeDiscountInfoModels.push(discountInfoModel);
				}
				if (periodDiscountInfoModel) {
					addonDefaultFeeDiscountInfoModels.push(periodDiscountInfoModel);
				}
				const addonDefaultFeeOrderDetail = {
					channelItemSimpleModel: null,
					channelItemType: 'DEFAULT_FEE',
					itemCode: addonItem.defaultFeePolicyModel.code,
					totalOrderCount: 1,
					existingCount: 0,
					addCount: 1,
					price: addonItem.defaultFeePolicyModel.price, // 단가
					finalPrice: addonItem.defaultFeeDiscountPrice,
					note: '기본료',
					useFulfillment: false,
					discountInfoModels: addonDefaultFeeDiscountInfoModels,
					status: 'USING',
					itemCancelInfoModel: null,
				};

				// membershipFeeOrderDetail(설치비 상세)
				const addonMembershipFeeDiscountIdx = addonItem.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'MEMBERSHIP_FEE' && obj.useNew,
				);
				let addonMembershipFeeDiscountInfoModels = [];
				if (addonMembershipFeeDiscountIdx > -1) {
					const discountPolicyModel = addonItem.discountPolicyModels[addonMembershipFeeDiscountIdx];

					const discountInfoModel = {
						discountName: discountPolicyModel.name,
						discountType: 'PROMOTION',
					};
					if (discountPolicyModel.benefitType == 'RATE') {
						// 율 할인
						discountInfoModel['discountRate'] = discountPolicyModel.num;
					} else if (discountPolicyModel.benefitType == 'AMOUNT') {
						// 금액할인
						discountInfoModel['discountPrice'] = discountPolicyModel.num;
					}

					addonMembershipFeeDiscountInfoModels.push(discountInfoModel);
				}
				const addonMembershipFeeOrderDetail = {
					channelItemSimpleModel: null,
					channelItemType: 'MEMBERSHIP_FEE',
					itemCode: addonItem.membershipFeePolicyModel.code,
					totalOrderCount: 1,
					existingCount: 0,
					addCount: 1,
					price: addonItem.membershipFeePolicyModel.price,
					finalPrice: addonItem.membershipFeeDiscountPrice,
					note: null,
					discountInfoModels: addonMembershipFeeDiscountInfoModels,
					status: 'USING',
					itemCancelInfoModel: null,
				};
				// to-be : addUserFeeOrderDetails
				let addonAddUserFeeOrderDetails = [];
				const addonAddUserFeeDiscountIdx = addonItem.discountPolicyModels.findIndex(
					(obj) => obj.itemType == 'ADD_USER' && obj.useNew,
				);
				let addonAddUserFeeDiscountInfoModels = [];
				if (addonAddUserFeeDiscountIdx > -1) {
					// discountPolicyModel 만들기
					const discountPolicyModel = addonItem.discountPolicyModels[addUserFeeDiscountIdx];

					const discountInfoModel = {
						discountName: discountPolicyModel.name,
						discountType: 'PROMOTION',
					};
					if (discountPolicyModel.benefitType == 'RATE') {
						// 율 할인
						discountInfoModel['discountRate'] = discountPolicyModel.num;
					} else if (discountPolicyModel.benefitType == 'AMOUNT') {
						// 금액할인
						discountInfoModel['discountPrice'] = discountPolicyModel.num;
					}

					addonAddUserFeeDiscountInfoModels.push(discountInfoModel);
				}

				if (periodDiscountInfoModel) {
					addonAddUserFeeDiscountInfoModels.push(periodDiscountInfoModel);
				}
				addonItem.addUserDiscountMatrix.forEach((data) => {
					addonAddUserFeeOrderDetails.push({
						channelItemSimpleModel: null,
						channelItemType: data.addUserIntervals.channelItemType,
						itemCode: data.code,
						itemName: '사용자추가(' + data.addUserIntervals.min + '~' + data.addUserIntervals.max + ')',
						totalOrderCount: data.discountCnt,
						existingCount: 0,
						addCount: data.discountCnt,
						price: data.price,
						finalPrice: data.totPrice,
						note: null,
						discountInfoModels: addonAddUserFeeDiscountInfoModels,
						status: 'USING',
						itemCancelInfoModel: null,
					});
				});
				//itemOrderDetails(옵션상품리스트)
				let addonItemOrderDetails = [];

				addonItem.optionItemSelected.forEach((data) => {
					data['billingType'] = data.channelItemSalesSectionModel.billingType;
					data['noCharge'] = data.channelItemSalesSectionModel.noCharge;

					addonItemOrderDetails.push({
						channelItemSimpleModel: data,
						channelItemType: data.itemType,
						itemCode: data.code,
						itemName: data.name,
						totalOrderCount: data.orderCount,
						existingCount: 0,
						addCount: data.orderCount,
						price: data.channelItemSalesSectionModel.price,
						finalPrice: data.finalPrice,
						note: data.name,
						discountInfoModels: [],
						status: 'USING',
						itemCancelInfoModel: null,
					});
				});

				let freeTrialOrderType = 'NONE';
				let addonEndDate = endDate;

				if (addonItem.freeTrialPolicyModel) {
					if (addonItem.freeTrialPolicyModel.usePolicy) {
						freeTrialOrderType = 'FREE_TRIAL';
						addonEndDate = addonItem.freeTrialExpiredDate;
					}
				}

				const addonChannelOrderInfoModel = {
					addonChannelModel: addonChannelModel,
					addonChannelBaseInfoModel: addonChannelBaseInfoModel,
					applyDate: current,
					endDate: addonEndDate,
					itemOrderDetails: addonItemOrderDetails,
					new: true,
					freeTrialOrderType: freeTrialOrderType,
				};

				if (addonItem.membershipFeePolicyModel.usePolicy) {
					addonChannelOrderInfoModel['membershipFeeOrderDetail'] = addonMembershipFeeOrderDetail;
				}

				if (addonItem.defaultFeePolicyModel.usePolicy) {
					addonChannelOrderInfoModel['defaultFeeOrderDetail'] = addonDefaultFeeOrderDetail;
				}

				if (addonItem.addUserIntervalPolicyModel.usePolicy) {
					addonChannelOrderInfoModel['addUserFeeOrderDetails'] = addonAddUserFeeOrderDetails;
				}

				addonChannelOrderInfoModels.push(addonChannelOrderInfoModel);
			});

			const totPrices = this.$store.getters.getSummuryPrices;
			const param = {
				id: null,
				createdAt: null,
				updatedAt: null,
				createdBy: null,
				updatedBy: null,
				customer: customer,
				representativeCustomer: representativeCustomer,
				membershipModel: membershipModel,
				baseInfoModel: baseInfoModel,
				orderSummary: orderSummary,
				baseChannelOrderInfoModel: baseChannelOrderInfoModel,
				addonChannelOrderInfoModels: addonChannelOrderInfoModels,
				partnerSimpleModel: partnerSimpleModel,
				userCnt: baseItemSelected.userCnt,
				amount: totPrices.finalPayPrice,
				serviceType: baseItemSelected.serviceCategoryModel.serviceType,
				payType: payType,
				totalPrice: totPrices.finalPayPrice,
				paymentDate: null,
				new: true,
				issuedBill: this.payMethodInfo.issuedBill,
				emailRecipientModel: emailRecipientModel,
			};
			return param;
		},
		async setPayMethodInfo(payMethodInfo) {
			this.$set(this.input, 'payMethodInfo', payMethodInfo);
		},
		async setTermsAgreement(termsAgreementInfo) {
			this.$set(this.input, 'termsAgreementInfo', termsAgreementInfo);
		},
	},
	components: {
		OrderInfo,
		PayMethod,
		StepFooter,
		TermsAgreementInfo,
		//  Kiwoompay,
	},
};
</script>

<style scoped>
#payPopup {
	/*
         높이 넓이를 자식창에 맞도록 변경
         height 값의 경우 100vh가 맞지만 top: -150px 을 해주었기 때문에 비율 만큼 더했다!
         */
	height: 116vh;
	width: 100vw;
	z-index: 101;
	top: 300px;
	left: 800px;
	/*상단 숨김 코드*/
}
.animate {
	text-align: center;
	opacity: 0.5;
	animation: pulse 1s infinite;
	animation-delay: 1s;
	display: inline-block;
}
</style>
